<template>
<div class="CardView" v-bind:style="{ backgroundColor: backgroundColor}">
    <div class="CardHeader">
        <span class="NameCard">
            {{title}}
        </span>
        <span class="ValueCard mt-8">
            {{mainText}}
            <md-tooltip>{{mainText}}</md-tooltip>
        </span>
    </div>
    <div class="CardFooter">
            <div class="ValueBalance">
                <span class="TitleValue">
                    {{footfallNumber.toLocaleString("en-US")}}
                </span>
            </div>
            <span class="SubText">
                {{$t("dashboard.Footfall")}}
            </span>
    </div>
</div>
</template>
<script>
export default { 
    name: "CardView",
    props: {
        title: String,
        mainText: String,
        footfallNumber: Number,
        backgroundColor: String
    },
}
</script>
<style lang="scss">
.CardView {
    padding: 16px;
    background-color:#5C8EBF;
    height: 197px;
    min-width: 260px;
    .CardHeader {
        width: 100%;
        border-bottom: solid 1px #689BC8 ;
        .NameCard {
            font-family: 'Roboto';
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #FFFFFF;
            text-align: center;
            display: inline-block;
            width: 100%;
        }
        .ValueCard {
            font-family: 'Roboto';
            font-weight: 500;
            font-size: 40px;
            line-height: 60px;
            text-align: center;
            color: #FFFFFF;
            width: 100%;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .CardFooter {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding-top: 18px;
        .ValueBalance {
            .TitleValue {
                font-family: 'Roboto';
                font-weight: 500;
                font-size: 28px;
                line-height: 32px;
                color: #FFFFFF;
            }
        }
        
        .SubText {
            font-family: 'Roboto';
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #D9D9D9;
            white-space: nowrap; 
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
.mt-8 {
    margin-top: 8px;
}
</style>
