<template>
    <section class="wrapCardForListCardDashboardShopingMall mt-40">
        <CardSparkline 
            :title="titleForGate"
            :total="gate"
            :percentage="gatePercentage"
            :sparklineData="listGateData"
            :compareType="compareText"
        />
        <CardSparkline 
            :title="titleForTime"
            :total="timeAverage"
            :percentage="timeAveragePercentage"
            :sparklineData="listTimeAverage"
            :compareType="compareText"
        />
        <CardView 
            :title="titleForCrowdedDayCard"
            :mainText="mostCrowdedDay"
            :footfallNumber="mostCrowdedDayValue"
            :backgroundColor="backgroundColor1"
        />
        <CardView 
            :title="titleForCrowdedShopCard"
            :mainText="mostcrowdedShop"
            :footfallNumber="mostcrowdedShopValue"
            :backgroundColor="backgroundColor1"
        />
        <CardView 
            :title="titleForCrowdedHourCard"
            :mainText="rushHour"
            :footfallNumber="rushHourRange"
            :backgroundColor="backgroundColor1"
        />
        <CardView 
            :title="titleForCrowdedGateCard"
            :mainText="mostCrowdedGate"
            :footfallNumber="mostCrowdedGateName"
            :backgroundColor="backgroundColor1"
        />
        <CardView 
            :title="titleForQuietDayCard"
            :mainText="mostQuietDay"
            :footfallNumber="mostQuietDayValue"
            :backgroundColor="backgroundColor2"
        />
        <CardView 
            :title="titleForQuiethopCard"
            :mainText="mostQuietShop"
            :footfallNumber="mostQuietShopName"
            :backgroundColor="backgroundColor2"
        />
    </section>
</template>

<script>
import {
    CardSparkline,
    CardView,
} from "@/pages";
import { Api } from "@/api/index";

const overviewReportShopingMall = Api.get("overviewReportShopingMall");

export default {
    name: "List-Card-Report-ShopingMall",
    components: {
       CardSparkline,
       CardView
    },
    mounted() {
        this.dateStart = this.dateRange.dateStart
        this.dateEnd = this.dateRange.dateEnd
        this.getAPITotalGateInWeek()
    },
    props: {
        compareType: String,
        compareText: String,
        dateRange: Object,
    },
    methods: {
        checkNoData(value) {
            if (value == 100000001) {
                return 0
            } else {
                return value
            }
        },
        getAPITotalGateInWeek() {
            overviewReportShopingMall.get(this.dateStart, this.dateEnd, this.compareType).then(response => {
                this.rawData = response.data
            })
        }
    },
    watch: {
        dateRange: {
            handler: function(val) {
                this.dateStart = val["dateStart"]
                this.dateEnd = val["dateEnd"]
                this.getAPITotalGateInWeek()
            },
            deep: true
        },
        compareType() {
            this.getAPITotalGateInWeek()
        },
        rawData(val) {
            this.listGateData = val.listGate
            for (var i = 0; i < val.listTimeAverage.length; i++) {
                if (val.listTimeAverage[i] == 100000001) {
                    this.listTimeAverage.push(0)
                } else {
                    this.listTimeAverage.push(val.listTimeAverage[i])
                }
            }
            // this.listTimeAverage = val.listTimeAverage

            for (var i = 0; i < val.overviews.length; i++) {
                if (i == 0) {
                    this.gate = this.checkNoData(val.overviews[i].title)
                    this.gatePercentage = this.checkNoData(val.overviews[i].value)
                } else if (i == 1) {
                    this.timeAverage = 0
                    var hour = parseInt(this.timeAverage / 3600)
                    var dwellTime = ""
                    if (hour != 0) {
                        dwellTime = dwellTime +"h"
                    }
                    var redundantHour = this.timeAverage % 3600
                    var minutes = parseInt(redundantHour / 60)
                    if (localStorage.lang == null || localStorage.lang == "vn") {
                        this.timeAverage = dwellTime + minutes + "p"
                    } else {
                        this.timeAverage = dwellTime + minutes + "m"
                    }
                    this.timeAveragePercentage = 0
                } else if (i == 2) {
                    this.mostCrowdedDay = this.checkNoData(val.overviews[i].title).replaceAll("-", "/")
                    this.mostCrowdedDayValue = this.checkNoData(val.overviews[i].value)
                } else if (i == 3) {
                    this.mostcrowdedShop = this.checkNoData(val.overviews[i].title)
                    this.mostcrowdedShopValue = this.checkNoData(val.overviews[i].value)
                } else if (i == 4) {
                    this.rushHour = this.checkNoData(val.overviews[i].title)
                    this.rushHourRange = this.checkNoData(val.overviews[i].value)
                } else if (i == 5) {
                    this.mostCrowdedGate = this.checkNoData(val.overviews[i].title)
                    this.mostCrowdedGateName = this.checkNoData(val.overviews[i].value)
                } else if (i == 6) {
                    this.mostQuietDay = this.checkNoData(val.overviews[i].title).replaceAll("-", "/")
                    this.mostQuietDayValue = this.checkNoData(val.overviews[i].value)
                } else if (i == 7) {
                    this.mostQuietShop = this.checkNoData(val.overviews[i].title)
                    this.mostQuietShopName = this.checkNoData(val.overviews[i].value)
                }
            }
        }
    },

    data() {
        var self = this
        return {
            backgroundColor1: "#5C8EBF",
            backgroundColor2: "#7BBEFF",

            titleForGate: self.$t("reportShopingMall.titleForGateCard"),
            gate: 0,
            gatePercentage: 0,
            listGateData: [],

            titleForTime: self.$t("reportShopingMall.titleForTimeCard"),
            timeAverage: 0,
            timeAveragePercentage: 0,
            listTimeAverage: [],

            titleForCrowdedDayCard: self.$t("reportShopingMall.titleForCrowdedDayCard"),
            mostCrowdedDay: "",
            mostCrowdedDayValue: "",

            titleForCrowdedShopCard: self.$t("reportShopingMall.titleForCrowdedShopCard"),
            mostcrowdedShop: 0,
            mostcrowdedShopValue: "",

            titleForCrowdedHourCard: self.$t("reportShopingMall.titleForRushHourCard"),
            rushHour: 0,
            rushHourRange: "",

            titleForCrowdedGateCard: self.$t("reportShopingMall.titleForCrowdedGateCard"),
            mostCrowdedGate: "",
            mostCrowdedGateName: "",

            titleForQuietDayCard: self.$t("reportShopingMall.titleForQuietDayCard"),
            mostQuietDay: 0,
            mostQuietDayValue: "",

            titleForQuiethopCard: self.$t("reportShopingMall.titleForQuietShopCard"),
            mostQuietShop: "",
            mostQuietShopName: "",
            
            rawData: [],
            dateStart: 0,
            dateEnd: 0,
        }
    }
}
</script>

<style lang="scss">
.wrapCardForListCardDashboardShopingMall {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    row-gap: 16px;
    column-gap: 16px;
}
</style>
