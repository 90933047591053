<template>
    <section class="wrapCardForListCardDashboardShopingMall mt-40">
        <CardSparkline 
            :title="titleForGate"
            :total="gate"
            :percentage="gatePercentage"
            :sparklineData="listGateData"
            :compareType="compareType"
        />
        <CardSparkline 
            :title="titleForTime"
            :total="timeAverage"
            :percentage="timeAveragePercentage"
            :sparklineData="listTimeAverage"
            :compareType="compareType"
        />
        <CardView 
            :title="titleForCrowdedGateCard"
            :mainText="mostCrowdedGateName"
            :footfallNumber="mostCrowdedGate"
            :backgroundColor="backgroundColor1"
        />
        <CardView 
            :title="titleForCrowdedHourCard"
            :mainText="rushHourRange"
            :footfallNumber="rushHour"
            :backgroundColor="backgroundColor2"
        />

    </section>
</template>

<script>
import {
    CardSparkline,
    CardView,
} from "@/pages";
import { Api } from "@/api/index";

const overviewDashboardShopingMall = Api.get("overviewDashboardShopingMall");

export default {
    name: "List-Card-Dashboard-ShopingMall",
    components: {
       CardSparkline,
       CardView
    },
    mounted() {
        this.listTimeAverage = []
        overviewDashboardShopingMall.get().then(response => {
            this.gate = response.data.gate
            this.gatePercentage = response.data.gatePercentage
            this.listGateData = response.data.listGate

            if (response.data.timeAverage == 100000001) {
                this.timeAverage = 0
            } else {
                this.timeAverage = response.data.timeAverage
                var hour = parseInt(response.data.timeAverage / 3600)
                var dwellTime = ""
                if (hour != 0) {
                    dwellTime = dwellTime +"h"
                }
                var redundantHour = response.data.timeAverage % 3600
                var minutes = parseInt(redundantHour / 60)
                if (localStorage.lang == null || localStorage.lang == "vn") {
                    this.timeAverage = dwellTime + minutes + "p"
                } else {
                    this.timeAverage = dwellTime + minutes + "m"
                }
            }
            if (response.data.timeAveragePercentage == 100000001) {
                this.timeAveragePercentage = 0
            } else {
                this.timeAveragePercentage = response.data.timeAveragePercentage
            }
            for (var i = 0; i < response.data.listTimeAverage.length; i++) {
                if (response.data.listTimeAverage[i] == 100000001) {
                    this.listTimeAverage.push(0)
                } else {
                    this.listTimeAverage.push(response.data.listTimeAverage[i])
                }
            }
            // this.listTimeAverage = response.data.listTimeAverage

            this.mostCrowdedGate = response.data.mostCrowdedGate
            if (response.data.mostCrowdedGateName == "") {
                this.mostCrowdedGateName = this.$t("dashboard.NoData")
            } else {
                this.mostCrowdedGateName = response.data.mostCrowdedGateName
            }
            this.rushHour = response.data.rushHour
            this.rushHourRange = response.data.rushHourRange
        })
    },
    data() {
        var self = this
        return {
            titleForGate: self.$t("dashboardShopingMall.titleForGateCard"),
            gate: 0,
            gatePercentage: 0,
            listGateData: [],

            titleForTime: self.$t("dashboardShopingMall.titleForTimeCard"),
            timeAverage: 0,
            timeAveragePercentage: 0,
            listTimeAverage: [],

            titleForCrowdedGateCard: self.$t("dashboardShopingMall.titleForCrowdedGateCard"),
            mostCrowdedGate: "",
            mostCrowdedGateName: "",

            titleForCrowdedHourCard: self.$t("dashboardShopingMall.titleForCrowdedHourCard"),
            rushHour: 0,
            rushHourRange: "",
            
            backgroundColor1: "#5C8EBF",
            backgroundColor2: "#7BBEFF",
            compareType: self.$t("dashboard.ComparedToYesterday")
        }
    }
}
</script>

<style lang="scss">
.wrapCardForListCardDashboardShopingMall {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    row-gap: 0;
    column-gap: 16px;
}
</style>
