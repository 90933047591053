<template>
    <div class="CardSparkline">
        <div class="CardHeader">
            <span class="NameCard"> {{ title }} </span>
            <span class="ValueCard mt-8">
                {{ total.toLocaleString("en-US") }}
                <md-tooltip>{{ total.toLocaleString("en-US") }}</md-tooltip>
            </span>
        </div>
        <div class="CardFooter">
            <div class="SparkLine">
                <sparkline
                    width="100"
                    height="40"
                    :indicatorStyles="spIndicatorStyles3"
                    :tooltipProps="spTooltipProps3"
                >
                    <sparklineCurve
                        :data="sparklineData"
                        :limit="sparklineData.length"
                        :spotlight="spIndex3"
                        :styles="spCurveStyles3"
                        :spotStyles="spSpotStyles3"
                        :spotProps="spSpotProps3"
                        :refLineType="spRefLineType3"
                        :refLineStyles="spRefLineStyles3"
                        :textStyles="spTextStyles3"
                    />
                </sparkline>
            </div>
            <div class="Balance">
                <div class="ValueBalance" v-if="percentage >= 0">
                    <span class="TitleValueUp"> {{ percentage }}% </span>
                    <span class="material-icons icon-up">arrow_upward</span>
                </div>
                <div class="ValueBalance" v-if="percentage < 0">
                    <span class="TitleValueDown">
                        {{
                            percentage
                                .toString()
                                .slice(1, percentage.toString().length)
                        }}%
                    </span>
                    <span class="material-icons icon-down">arrow_downward</span>
                </div>
                <span class="SubText">
                    {{ compareType }}
                    <md-tooltip>{{ compareType }}</md-tooltip>
                </span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "CardSparkline",
    props: {
        title: String,
        total: Number,
        percentage: Number,
        sparklineData: Array,
        compareType: String
    },
    watch: {
        percentage(val) {
            if (val < 0) {
                this.spCurveStyles3 = {
                    stroke: "#FF5050",
                    fill: "#000"
                };
            } else {
                this.spCurveStyles3 = {
                    stroke: "#28FFBF",
                    fill: "#000"
                };
            }
        }
    },
    data() {
        return {
            spCurveStyles3: {
                stroke: "#28FFBF",
                fill: "#28FFBF"
            },
            spRefLineType3: "avg",
            spRefLineStyles3: {
                stroke: "#d14",
                strokeOpacity: 1,
                strokeDasharray: "2, 2"
            },
            spIndicatorStyles3: false
        };
    }
};
</script>
<style lang="scss">
.CardSparkline {
    padding: 16px;
    background-color: #064b6c;
    height: 197px;
    min-width: 260px;
    .CardHeader {
        width: 100%;
        border-bottom: solid 1px #246686;
        .NameCard {
            font-family: "Roboto";
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
            text-align: center;
            display: inline-block;
            width: 100%;
        }
        .ValueCard {
            font-family: "Roboto";
            font-weight: 500;
            font-size: 56px;
            line-height: 60px;
            text-align: center;
            color: #ffffff;
            width: 100%;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .CardFooter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 18px;
        .SparkLine {
            display: flex;
            align-items: center;
        }
        .Balance {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            min-width: 140px;
            .ValueBalance {
                .TitleValueUp {
                    font-family: "Roboto";
                    font-weight: 500;
                    font-size: 28px;
                    line-height: 32px;
                    color: #28ffbf;
                }
                .TitleValueDown {
                    font-family: "Roboto";
                    font-weight: 500;
                    font-size: 28px;
                    line-height: 32px;
                    color: #ff5050;
                }
                .icon-up {
                    color: #28ffbf;
                }
                .icon-down {
                    color: #ff5050;
                }
            }

            .SubText {
                font-family: "Roboto";
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #d9d9d9;
                white-space: nowrap;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}
.mt-8 {
    margin-top: 8px;
}
</style>
