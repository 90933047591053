<template>
    <div class="wrapCardRetail">
        <span class="CardName">
            {{title}}
             <md-tooltip>{{title}}</md-tooltip>
        </span>
        <span ref="valueCard" class="ValueCard mt-8">
            {{total}}
            <md-tooltip>{{total}}</md-tooltip>
        </span>
        <span class="BalanceDown mt-8" v-if="icon=='arrow_downward'">
            <span class="material-icons">{{icon}}</span>
            <div class="wrapText ml-8">
                <span class="ValueBalance">{{percentage.replace("-","")}}%</span>
                <span class="SubBalance">{{$t(compareText).toLowerCase()}} <md-tooltip>{{$t(compareText).toLowerCase()}} </md-tooltip></span>
            </div>
        </span>
        <span class="Balance mt-8" v-if="icon=='arrow_upward'">
            <span class="material-icons">{{icon}}</span>
            <div class="wrapText ml-8">
                <span class="ValueBalance">{{percentage}}%</span>
                <span class="SubBalance">{{$t(compareText).toLowerCase()}} <md-tooltip>{{$t(compareText).toLowerCase()}} </md-tooltip></span>
            </div>
        </span>
    </div>
</template>
<script>
export default {
    props: {
        title: String,
        total: String,
        percentage: String,
        icon: String,
        compareText: {
            type: String,
            default: "dashboard.ComparedToYesterday"
        },
        fontSize: {
            type: String,
            default: "64px"
        }
    },
    mounted() {
        this.$refs.valueCard.style.fontSize = this.fontSize
    },
    watch: {
        fontSize(val) {
            this.$refs.valueCard.style.fontSize = val
        }
    }
}
</script>
<style lang="scss" scoped>
    .wrapCardRetail {
        display: flex;
        flex-direction: column;
        .CardName {
            font-family: 'Roboto';
            font-weight: 400;
            font-size: 24px;
            line-height: 32px;
            color: #1F1F1F;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 360px;
        }
        .ValueCard {
            font-family: 'Roboto';
            font-weight: 800;
            font-size: 64px;
            line-height: 80px;
            color: #104579;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 360px;
        }
        .Balance {
            display: flex;
            align-items: center;
            .material-icons {
                color: #107C10;
            }
            .wrapText {
                .ValueBalance {
                    font-family: 'Roboto';
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 32px;
                    color: #107C10;
                    margin-right: 4px;
                }
                .SubBalance {
                    font-family: 'Roboto';
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #8C8C8C;
                }
            }
        }
        .BalanceDown {
            display: flex;
            align-items: center;
            .material-icons {
                color: #FF4343
;
            }
            .wrapText {
                .ValueBalance {
                    font-family: 'Roboto';
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 32px;
                    color: #FF4343
;
                    margin-right: 4px;
                }
                .SubBalance {
                    font-family: 'Roboto';
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #8C8C8C;
                }
            }
        }
    }
@media only screen and (max-width: 1920px) {
    .wrapCardRetail {
        .CardName {
            max-width: 360px;
            font-size: 17px;
        }
        .ValueCard {
            max-width: 360px;
            font-size: 40px
        }
    }
}
@media only screen and (max-width: 1788px) {
    .wrapCardRetail {
        .CardName {
            max-width: 208px;
        }
        .ValueCard {
            max-width: 208px;
        }
        .BalanceDown {
            .wrapText {
                display: flex;
                align-items: baseline;
                .SubBalance {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 102px;
                    display: inline-block;
                }
            }
        }
        .Balance {
            .wrapText {
                display: flex;
                align-items: baseline;
                .SubBalance {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 102px;
                    display: inline-block;
                }
            }
        }
    }
}
@media only screen and (max-width: 1600px) {
    .wrapCardRetail {
        .CardName {
            max-width: 208px;
        }
        .ValueCard {
            max-width: 208px;
        }
        .BalanceDown {
            .wrapText {
                .SubBalance {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 88px;
                    display: inline-block;
                }
            }
        }
        .Balance {
            .wrapText {
                .SubBalance {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 88px;
                    display: inline-block;
                }
            }
        }
    }
}
@media only screen and (max-width: 1366px) {
    .wrapCardRetail {
        .CardName {
            max-width: 184px;
        }
        .ValueCard {
            max-width: 184px;
        }
        .BalanceDown {
            .wrapText {
                display: flex;
                align-items: baseline;
                .SubBalance {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 48px;
                    display: inline-block;
                }
            }
        }
        .Balance {
            .wrapText {
                 display: flex;
                align-items: baseline;
                .SubBalance {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 48px;
                    display: inline-block;
                }
            }
        }
    }
}
@media only screen and (max-width: 1314px) {
    .wrapCardRetail {
        .CardName {
            max-width: 148px;
        }
        .ValueCard {
            max-width: 148px;
        }
        .BalanceDown {
            .wrapText {
                 display: flex;
                align-items: baseline;
                .SubBalance {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 28px;
                    display: inline-block;
                }
            }
        }
        .Balance {
            .wrapText {
                display: flex;
                align-items: baseline;
                .SubBalance {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 28px;
                    display: inline-block;
                }
            }
        }
    }
}
</style>